.homeformarea{
	float: left;
	width: 100%;
	background: url(../assets/contactbg.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 50px 0;
	color: #fff;
	position: relative;
}

.homeformarea::before{
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.25);
}

.homeareasteps{
	float: left;
	width: 100%;
	padding: 90px 0;
	background: #fff;
	padding-bottom: 140px;
}